import React, { useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import DashboardPage from "../Pages/Dashboard/Dashboard";
import HomePage from "../Pages/Home/Home";
import LoginPage from "../Pages/Login/Login";
import NotFound from "../Pages/NotFound/NotFound";

const PagesRoutes = ({ isAuthenticated, user }) => {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const meetingId = searchParams.get("meeting");
  const handleLogin = () => {
    if (searchParams.has("meeting")) {
      return <Navigate to={`/login${location.pathname}${location.search}`} />;
    }
    return <Navigate to="/login" />;
  };

  return (
    <Routes>
      <Route
        path="/login"
        element={
          isAuthenticated ? (
            <Navigate to={meetingId ? `/?meeting=${meetingId}` : "/"} />
          ) : (
            <LoginPage />
          )
        }
      />

      <Route
        path="/"
        element={
          isAuthenticated ? (
            user.role === "admin" ? (
              <DashboardPage />
            ) : (
              <HomePage />
            )
          ) : (
            handleLogin()
          )
        }
      />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default PagesRoutes;
