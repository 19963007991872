import AccountCircle from "@mui/icons-material/AccountCircle";
import {
  AppBar,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { updatePasswordApi } from "../../../API/auth";
import { logoutSuccess as logoutAction } from "../../../store/actions/auth";
import theme from "../../../theme/theme";
import {
  errorResponseHandler,
  successResponseHandler,
} from "../../../utils/reposeAlert";
import ChangePasswordModal from "../../Login/ChangePasswordModal";
const Header = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const open = Boolean(openMenu);
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleMenu = (event) => {
    setOpenMenu(event.currentTarget);
  };

  const handleClose = () => {
    setOpenMenu(null);
  };

  const toggleChangePasswordModal = (open) => {
    setOpenChangePasswordModal(open);
    setOldPassword("");
    setNewPassword("");
  };

  const changePasswordHandler = async () => {
    await updatePasswordApi({
      oldPassword,
      newPassword,
    })
      .then((response) => {
        if (response.status === 200) {
          successResponseHandler(
            "Update Password Success",
            response.data.message
          );
          toggleChangePasswordModal(false);
          setOldPassword("");
          setNewPassword("");
        }
      })
      .catch((error) => {
        const msg = error.response.data;
        errorResponseHandler("Update Password Failed", msg);
      })
      .finally({});
  };

  const logoutHandler = () => {
    dispatch(logoutAction());
    navigate("/login");
    handleClose();
  };

  return (
    <AppBar
      position="relative"
      sx={{
        backgroundColor: theme.palette.primary.main,
        padding: {
          xs: "0.5rem 0",
        },
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
      }}
    >
      <Container maxWidth="xl">
        {user && (
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0 !important",
            }}
          >
            <Grid container alignItems="center">
              <Grid item>
                <img
                  src="/assets/images/logo2025.png"
                  alt="Logo"
                  height={55}
                  style={{ marginRight: "5px" }}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" justifyContent="flex-end">
              <Grid item>
                <Typography
                  variant="h6"
                  sx={{
                    color: theme.palette.white.main,
                    marginRight: "5px",
                    fontSize: "1rem",
                  }}
                >
                  {`${user.first} ${user.last}`}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="white"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={openMenu}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem onClick={() => toggleChangePasswordModal(true)}>
                    Change Password
                  </MenuItem>
                  <MenuItem onClick={logoutHandler}>Logout</MenuItem>
                </Menu>
              </Grid>
            </Grid>
            <ChangePasswordModal
              openModal={openChangePasswordModal}
              oldPassword={oldPassword}
              newPassword={newPassword}
              setOldPassword={setOldPassword}
              setNewPassword={setNewPassword}
              toggleModal={toggleChangePasswordModal}
              changePasswordHandler={changePasswordHandler}
            />
          </Toolbar>
        )}
      </Container>
    </AppBar>
  );
};

export default Header;
