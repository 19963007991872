import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import PagesRoutes from "./PagesRoutes";

const PagesRouter = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const user = useSelector((state) => state.auth.user);
  return (
    <main>
      <Router>
        <PagesRoutes isAuthenticated={isAuthenticated} user={user} />
      </Router>
    </main>
  );
};

export default PagesRouter;
