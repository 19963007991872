import { axiosInstance } from "../utils/axios";

// Get users API
export const getUsersApi = async () => {
  return await axiosInstance.get(`users`);
};

// GET Users By Company
export const getUsersByCompanyApi = async (company,startDate, endDate) => {
  return await axiosInstance.get(`users/company/${company}?startDate=${startDate}&endDate=${endDate}`);
};
