import { Autocomplete, Chip, TextField } from "@mui/material";
import React from "react";

const MeetingMultipleSelection = ({ options, selected, setSelected, disabled }) => {
  return (
    <Autocomplete
      multiple
      options={options}
      getOptionDisabled={(option) => option.status === "busy"}
      getOptionLabel={(option) => (
        <div>
          <span>{option.name}</span>
          {option.status === "busy" && <span style={{ color: "red" }}> (Busy)</span>}
        </div>
      )}
      renderInput={(params) => <TextField {...params} label="Select Invitees" variant="outlined" fullWidth />}
      value={selected}
      onChange={(event, newValue) => {
        setSelected(newValue);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            label={option.name}
            {...getTagProps({ index })}
            style={{
              borderRadius: "0.5rem",
            }}
          />
        ))
      }
      disabled={disabled}
    />
  );
};

export default MeetingMultipleSelection;
