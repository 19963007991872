import { Box, Button, Grid, Paper } from "@mui/material";
import React from "react";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useSelector } from "react-redux";
import theme from "../../../theme/theme";
import { formatDate } from "../../../utils/formateDate";
import MeetingCompanyName from "../../Meeting/MeetingCompanyName";
import MeetingDate from "../../Meeting/MeetingDate";
import MeetingDescription from "../../Meeting/MeetingDescription";
import MeetingOrganizer from "../../Meeting/MeetingOrganizer";
import MeetingStatus from "../../Meeting/MeetingStatus";
import MeetingTitle from "../../Meeting/MeetingTitle";

const MeetingBox = ({ meeting, openMeetingModal }) => {
  const user = useSelector((state) => state.auth.user);
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Paper
        elevation={3}
        sx={{
          position: "relative",
          border: `${user.id === meeting.owner_id ? `1px solid ${theme.palette.primary.main}` : "none"}`,
          borderRadius: "10px",
        }}
      >
        <Box
          key={meeting.id}
          p={2}
          sx={{
            position: "relative",
          }}
        >
          <MeetingStatus status={meeting.meeting_status} />

          <MeetingTitle title={meeting.title} />
          <MeetingDate date={`${formatDate(meeting.start_date, meeting.end_date)}`} />
          <MeetingCompanyName company={meeting.company} />
          {user && (
            <MeetingOrganizer
              organizer={`${meeting.user.first} ${meeting.user.last} - (${meeting.user.company}) - (${meeting.user.position})`}
              user={user}
              meeting={meeting}
            />
          )}
          <MeetingDescription
            description={
              meeting.description.length > 160 ? `${meeting.description.substring(0, 160)}...` : meeting.description
            }
          />
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="outlined"
              endIcon={<ChevronRightIcon sx={{ fontSize: 40 }} />}
              onClick={() => openMeetingModal(meeting.id)}
              sx={{
                fontSize: 14,
                position: "relative",
                color: `${theme.palette.gray.main}`,
                border: `1px solid ${theme.palette.gray.main}`,
                borderTop: "none",
                zIndex: "1",
                borderRadius: "0",
                textTransform: "capitalize",

                "&:hover": {
                  borderColor: theme.palette.primary.main,
                  borderTop: "none",
                  background: "none",
                  color: theme.palette.white.main,
                  "& > div": {
                    borderTopColor: theme.palette.primary.main,
                  },
                },
                "&::after": {
                  content: '""',
                  width: 0,
                  height: "100%",
                  position: "absolute",
                  backgroundColor: theme.palette.primary.main,
                  left: 0,
                  top: 0,
                  transition: "all .3s",
                  zIndex: "-1",
                },
                "&:hover::after": {
                  width: "100%",
                  transition: "all .3s",
                },
              }}
            >
              Show Details
              <Box
                sx={{
                  borderTop: `1px solid ${theme.palette.gray.main}`,
                  width: "calc(100% - 35px)",
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              />
            </Button>
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
};

export default MeetingBox;
