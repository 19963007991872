import { Autocomplete, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import React, { useState } from "react";

const FilterTable = ({ allSchedules, setFilteredSchedules }) => {
  const [status, setStatus] = useState("");
  const [company, setCompany] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [fromDateToSubmit, setFromDateToSubmit] = useState("");
  const [toDateToSubmit, setToDateToSubmit] = useState("");

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleCompanyChange = (e) => {
    setCompany(e.target.value);
  };

  const handleFromDateChange = (date) => {
    const fromDateCopy = new Date(Number(date));
    const offset = fromDateCopy.getTimezoneOffset();
    fromDateCopy.setMinutes(fromDateCopy.getMinutes() - offset);
    setFromDateToSubmit(fromDateCopy);
    setFromDate(date);
  };

  const handleToDateChange = (date) => {
    const toDateCopy = new Date(Number(date));
    const offset = toDateCopy.getTimezoneOffset();
    toDateCopy.setMinutes(toDateCopy.getMinutes() - offset);
    setToDateToSubmit(toDateCopy);
    setToDate(date);
  };

  const handleFilter = () => {
    const statusFilter = status ? (item) => item.meeting_status === status : (item) => item;
    const companyFilter = company ? (item) => item.company === company : (item) => item;
    const fromDateFilter = fromDate
      ? (item) => new Date(item.start_end_date.split(" - ")[0]).getTime() >= new Date(fromDateToSubmit).getTime()
      : (item) => item;
    const toDateFilter = toDate
      ? (item) => new Date(item.start_end_date.split(" - ")[0]).getTime() <= new Date(toDateToSubmit).getTime()
      : (item) => item;

    const filteredSchedules = allSchedules.filter((item) => {
      return statusFilter(item) && companyFilter(item) && fromDateFilter(item) && toDateFilter(item);
    });

    setFilteredSchedules(filteredSchedules);
  };

  const handleClear = () => {
    setFilteredSchedules(allSchedules);
    setStatus("");
    setCompany("");
    setFromDate("");
    setToDate("");
  };

  const companies = allSchedules
    .map((item) => item.company)
    .filter((item, index, self) => self.indexOf(item) === index);

  const minDateTime = dayjs("2023-05-14T00:00:00");
  const maxDateTime = dayjs("2023-05-17T23:59:59");
  const minTime = dayjs().set("hour", 0).set("minute", 0).set("second", 0);
  const maxTime = dayjs().set("hour", 23).set("minute", 59).set("second", 0);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-end",
        marginBottom: "1rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          padding: "1rem",
        }}
      >
        <FormControl variant="outlined" sx={{ minWidth: 240, marginRight: "1rem" }}>
          <InputLabel id="status-label">Status</InputLabel>
          <Select labelId="status-label" id="status" value={status} onChange={handleStatusChange} label="Status">
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value="pending">Pending</MenuItem>
            <MenuItem value="finished">Finished</MenuItem>
            <MenuItem value="cancelled">Cancelled</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" sx={{ minWidth: 240, marginRight: "1rem", paddingTop: "8px" }}>
          <Grid item xs={12} md={12}>
            <Autocomplete
              options={companies}
              getOptionLabel={(option) => option}
              renderInput={(params) => <TextField {...params} label="Select Companies" variant="outlined" fullWidth />}
              onChange={(event, newValue) => {
                setCompany(newValue);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={company}
            />
          </Grid>
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DateTimePicker"]}>
            <DateTimePicker
              label="From Date"
              onChange={handleFromDateChange}
              value={fromDate}
              minDateTime={minDateTime}
              maxDateTime={maxDateTime}
              minTime={minTime}
              maxTime={maxTime}
            />
          </DemoContainer>
        </LocalizationProvider>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DateTimePicker"]}>
            <DateTimePicker
              label="To Date"
              onChange={handleToDateChange}
              value={toDate}
              minDateTime={minDateTime}
              maxDateTime={maxDateTime}
              minTime={minTime}
              maxTime={maxTime}
            />
          </DemoContainer>
        </LocalizationProvider>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "1rem", width: "100%", padding: "1rem" }}>
        <Button variant="outlined" onClick={handleClear}>
          Clear
        </Button>
        <Button variant="contained" onClick={handleFilter}>
          Filter
        </Button>
      </Box>
    </Box>
  );
};

export default FilterTable;