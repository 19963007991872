import { setTokens, removeTokens } from "../../utils/storage";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const loginSuccess = (tokens) => {
  setTokens(tokens);
  return {
    type: LOGIN_SUCCESS,
    payload: tokens,
  };
};

export const logoutSuccess = () => {
  removeTokens();
  return {
    type: LOGOUT_SUCCESS,
  };
};
