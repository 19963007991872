export const setTokens = ({ accessToken, refreshToken }) => {
  localStorage.setItem("accessToken", accessToken);
  localStorage.setItem("refreshToken", refreshToken);
};

export const getAccessToken = () => {
  const accessToken = localStorage.getItem("accessToken");

  if (!accessToken || accessToken === "undefined") {
    return null;
  }

  return accessToken;
};

export const getRefreshToken = () => {
  const refreshToken = localStorage.getItem("refreshToken");

  if (!refreshToken || refreshToken === "undefined") {
    return null;
  }

  return refreshToken;
};

export const removeTokens = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
};
