import { Box, Button, Grid, Modal, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import theme from "../../../theme/theme";

import { useDispatch } from "react-redux";
import { addScheduleApi, rescheduleMeetingApi } from "../../../API/schedule";
import { getUsersApi, getUsersByCompanyApi } from "../../../API/users";
import { updateMeetingSuccess as updateMeetingAction } from "../../../store/actions/schedule";
import { errorResponseHandler, successResponseHandler } from "../../../utils/reposeAlert";
import MeetingDatePicker from "../../Meeting/MeetingDatePicker";
import MeetingInput from "../../Meeting/MeetingInput";
import MeetingMultipleSelection from "../../Meeting/MeetingMultipleSelection";
import MeetingSingleSelection from "../../Meeting/MeetingSingleSelection";
import MeetingTextarea from "../../Meeting/MeetingTextarea";
import SharedButton from "../../Shared/SharedButton";

const RescheduleModal = ({ openModal, toggleModal, meeting }) => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [startTimeToSubmit, setStartTimeToSubmit] = useState(null);
  const [endTimeToSubmit, setEndTimeToSubmit] = useState(null);
  const [description, setDescription] = useState("");
  const [selectedCompanies, setSelectedCompanies] = useState(null);
  const [selectedInvitees, setSelectedInvitees] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [inviteeOptions, setInviteeOptions] = useState([]);
  const [disabledAddMeeting, setDisabledAddMeeting] = useState(false);

  const handleStartTimeChange = (date) => {
    const startDateCopy = new Date(Number(date));
    const offset = startDateCopy.getTimezoneOffset();
    const ammanOffset = 180;
    startDateCopy.setMinutes(startDateCopy.getMinutes() - offset);
    startDateCopy.setMinutes(startDateCopy.getMinutes() - ammanOffset);
    setStartTimeToSubmit(startDateCopy);
    setStartTime(date);
    const endDate = new Date(date);
    const endDateCopy = new Date(Number(startDateCopy));
    endDateCopy.setHours(endDateCopy.getHours() + 1);
    endDate.setHours(endDate.getHours() + 1);
    setEndTimeToSubmit(endDateCopy);
    setEndTime(endDate);
    setSelectedCompanies(null);
    setSelectedInvitees([]);
  };
  const handleEndTimeChange = (date) => {
    const endDateCopy = new Date(Number(date));
    const offset = endDateCopy.getTimezoneOffset();
    const ammanOffset = 180;
    endDateCopy.setMinutes(endDateCopy.getMinutes() - offset);
    endDateCopy.setMinutes(endDateCopy.getMinutes() - ammanOffset);
    setEndTimeToSubmit(endDateCopy);
    setEndTime(date);
    setSelectedCompanies(null);
    setSelectedInvitees([]);
  };

  const handleUpdateMeeting = async () => {};

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      xs: "100%",
      md: "45rem",
    },
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
  };

  useEffect(() => {
    setTitle(meeting.title);
    setDescription(meeting.description);
    setStartTime(new Date(meeting.start_date));
    setEndTime(new Date(meeting.end_date));
    setSelectedCompanies({ name: meeting.company, id: meeting.company });
    let invitees = [];
    meeting.invitations.map((item, index) => {
      invitees.push({
        name: `${item.user.first} ${item.user.last} - (${item.user.position})`,
        id: item.user.id,
      });
    });
    setSelectedInvitees(invitees);
    getCompanies();
    resetCompanyMembers();
  }, [meeting]);

  const resetCompanyMembers = async () => {
    await getUsersByCompanyApi(meeting.company)
      .then((response) => {
        if (response.status === 200) {
          let invitees = [];
          response.data.data.map((item, index) => {
            invitees.push({
              name: `${item.first} ${item.last} - (${item.position})`,
              id: item.id,
            });
          });
          setInviteeOptions(invitees);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally({});
  };

  const getCompanies = async () => {
    await getUsersApi()
      .then((response) => {
        if (response.status === 200) {
          let companies = [];
          response.data.data.rows.map((item, index) => {
            if (companies.findIndex((x) => x.name === item.company) === -1 && item.company) {
              companies.push({ name: item.company, id: item.company });
            }
          });
          setCompanyOptions(companies);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally({});
  };

  const companySelectHandler = async (company) => {
    if (!company || !startTime || !endTime) {
      setSelectedCompanies(null);
      setSelectedInvitees([]);
      setInviteeOptions([]);
      return;
    }
    setSelectedCompanies(company);
    setSelectedInvitees([]);
    await getUsersByCompanyApi(company.name, startTimeToSubmit.toISOString(), endTimeToSubmit.toISOString())
      .then((response) => {
        if (response.status === 200) {
          let invitees = [];
          response.data.data.map((item, index) => {
            invitees.push({
              name: `${item.first} ${item.last} - (${item.position})`,
              id: item.id,
              status: item?.status || "available",
            });
          });
          setInviteeOptions(invitees);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally({});
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setDisabledAddMeeting(true);
    if (!disabledAddMeeting) {
       
      const addScheduleData = {
        title,
        description,
        start_date: startTimeToSubmit === null ? startTime.toISOString() : startTimeToSubmit.toISOString(),
        end_date: endTimeToSubmit === null ? endTime.toISOString() : endTimeToSubmit.toISOString(),
        company: selectedCompanies.name,
        invitees: selectedInvitees.map((invite) => {
          return invite.id;
        }),
      };

      await rescheduleMeetingApi(meeting.id, addScheduleData)
        .then((response) => {
          successResponseHandler(`Updated`, response.data.message);
          dispatch(updateMeetingAction(response.data.data));
          toggleModal(false);
        })
        .catch((error) => {
          const msg = error.response.data;
          errorResponseHandler(`Failed`, msg);
        })
        .finally(() => {
          setDisabledAddMeeting(false);
        });
    }
  };

  return (
    <Modal
      open={openModal}
      onClose={() => toggleModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        zIndex: 10,
      }}
    >
      <Paper elevation={3} sx={modalStyle}>
        <Typography
          variant="h4"
          sx={{
            color: theme.palette.primary.main,
            fontWeight: "bold",
            marginBottom: "2rem",
          }}
        >
          Update Meeting
        </Typography>
        <form onSubmit={submitHandler}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MeetingInput label="Meeting title" title={title} setTitle={setTitle} />
            </Grid>
            <Grid item xs={12} md={6}>
              <MeetingDatePicker label="Start Time" handleChange={handleStartTimeChange} value={startTime} />
            </Grid>
            <Grid item xs={12} md={6}>
              <MeetingDatePicker label="End Time" handleChange={handleEndTimeChange} value={endTime} />
            </Grid>
            <Grid item xs={12}>
              <MeetingTextarea description={description}  setDescription={setDescription} />
            </Grid>
            {companyOptions.length ? (
              <Grid item xs={12} md={12}>
                <MeetingSingleSelection
                  options={companyOptions}
                  selected={selectedCompanies}
                  setSelected={companySelectHandler}
                  disabled={!startTime || !endTime}
                />
              </Grid>
            ) : null}

            <Grid item xs={12} md={12}>
              <MeetingMultipleSelection
                options={inviteeOptions}
                selected={selectedInvitees}
                setSelected={setSelectedInvitees}
                disabled={!selectedCompanies}
               
              />
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  borderTop: `1px solid ${theme.palette.primary.main}`,
                  paddingTop: "1rem",
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button variant="outlined" color="secondary" onClick={() => toggleModal(false)}>
                  Cancel
                </Button>
                <SharedButton
                  color="primary"
                  text="Update"
                  onAction={handleUpdateMeeting}
                  disabled={disabledAddMeeting}
                  type="submit  "
                />
              </Box>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Modal>
  );
};

export default RescheduleModal;