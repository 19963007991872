import { Box, Container, Grid, Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserScheduleApi } from "../../../API/schedule";
import { errorResponseHandler } from "../../../utils/reposeAlert";
import Layout from "../../Layout/Layout";

import { getUserScheduleSuccess as getUserScheduleAction } from "../../../store/actions/schedule";
import AddMeeting from "./AddMeeting";
import Meetings from "./Meetings";

const Home = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const meetings = useSelector((state) => state.schedules.meetings);
  useEffect(() => {
    getMeetings();
  }, []);

  const handlePageChange = (event, value) => {
    setPage(value);
    getMeetings(value);
  };

  const getMeetings = async (currentPage = page) => {
    await getUserScheduleApi(currentPage)
      .then((response) => {
        if (response.status === 200) {
          dispatch(getUserScheduleAction(response.data.data.rows));
          const totalPage = Math.ceil(response.data.data.count / 12);
          setTotalPages(totalPage);
        }
      })
      .catch((error) => {
        console.log(error);
        const msg = error.response.data;
        errorResponseHandler("Login Failed", msg);
      })
      .finally({});
  };
  return (
    <Layout>
      <Container maxWidth="xl">
        <Box mt={4}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={12}>
              <AddMeeting getMeetings={getMeetings} />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                paddingBottom: "2rem",
              }}
            >
              <Meetings />
            </Grid>
            {!!meetings.length && (
              <Grid item xs={12} md={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    width: "100%",
                    marginBottom: "2rem",
                  }}
                >
                  {totalPages > 1 && <Pagination count={totalPages} page={page} onChange={handlePageChange} />}
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>
    </Layout>
  );
};

export default Home;
