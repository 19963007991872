import BusinessIcon from "@mui/icons-material/Business";
import { Box, Typography } from "@mui/material";
import React from "react";
import theme from "../../theme/theme";

const MeetingCompanyName = ({ company }) => {
  return (
    <Box display="flex" alignItems="center" mb={1}>
      <BusinessIcon sx={{ marginRight: 1 }} />
      <Typography variant="subtitle1" sx={{ color: theme.palette.gray.main }}>
        {company}
      </Typography>
    </Box>
  );
};

export default MeetingCompanyName;
