import { combineReducers } from "redux";

import authReducer from "./auth";
import scheduleReducer from "./schedule";

const rootReducers = combineReducers({
  auth: authReducer,
  schedules: scheduleReducer,
});

export default rootReducers;
