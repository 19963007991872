import { useTheme } from "@emotion/react";
import { Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginApi } from "../../../API/auth";
import { loginSuccess as loginAction } from "../../../store/actions/auth";
import { errorResponseHandler } from "../../../utils/reposeAlert";
import LoginButton from "../../Login/LoginButton";
import LoginInput from "../../Login/LoginInput";
import LoginLogos from "../../Login/LoginLogos";
import  '../../../theme/style.css';
const Login = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    profileId: "",
    password: "",
  });

  const fields = [
    {
      name: "profileId",
      label: "Profile ID",
      placeholder: "Enter your Profile ID",
      type: "text",
    },
    {
      name: "password",
      label: "Password",
      placeholder: "Enter your Password",
      type: "password",
    },
  ];

  const changeHandler = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const loginData = {
      profileId: formData.profileId,
      password: formData.password,
    };
    await loginApi(loginData)
      .then((response) => {
        dispatch(loginAction(response.data.data.token));
        setFormData({
          profileId: "",
          password: "",
        });
        navigate("/");
      })
      .catch((error) => {
        const msg = error.response.data;
        errorResponseHandler("Login Failed", msg);
      })
      .finally(() => {});
  };

  return (
    <div  className="login-bg">

      <form onSubmit={submitHandler}>
            <Stack
              direction="column"
              justifyContent="center"
              sx={{
                minHeight: "80vh",
                padding: "3rem 0",
                width: {
                  xs: "90%",
                  md: "700px",
                },
                mx: "auto",
              }}
            >
              <LoginLogos />
              <Stack
                sx={{
                  paddingTop: "5rem",
                }}
              >
                <Stack
                  sx={{
                    paddingBottom: "1rem",
                  }}
                >
                  <Box>
                    <Typography
                      variant="h4"
                      component="h4"
                      sx={{
                        textAlign: "left",
                        color: theme.palette.primary.main,
                        fontWeight: "bold",
                      }}
                    >
                      LOGIN
                    </Typography>
                  </Box>
                </Stack>
                <Stack direction="column">
                  {fields.map((field) => (
                    <LoginInput
                      key={field.name}
                      name={field.name}
                      label={field.label}
                      placeholder={field.placeholder}
                      value={formData[field.name]}
                      onChange={changeHandler}
                      type={field.type}
                    />
                  ))}
                </Stack>
                <div style={{fontSize:14}}>* The initial password is your registered email address</div>
                <LoginButton />
              </Stack>
            </Stack>
          </form>
    </div>
 
  );
};

export default Login;
