import { GlobalStyles, ThemeProvider } from "@mui/material";
import React from "react";
import { Provider } from "react-redux";
import PagesRouter from "./components/PagesRouter/PagesRouter";
import { store } from "./store/store";
import "./styles/datepicker.css";
import globalStyles from "./theme/globalStyles";
import theme from "./theme/theme";
const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles styles={{ ...globalStyles }} />
      <Provider store={store}>
        <PagesRouter />
      </Provider>
    </ThemeProvider>
  );
};

export default App;
