import { Typography } from "@mui/material";
import React from "react";
import theme from "../../theme/theme";

const MeetingTitle = ({ title }) => {
  return (
    <Typography
      variant="h6"
      gutterBottom
      sx={{
        color: theme.palette.black.main,
      }}
    >
      {title}
    </Typography>
  );
};

export default MeetingTitle;
