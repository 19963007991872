import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Grid, IconButton, Modal, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { askForRescheduleApi } from "../../../API/schedule";
import theme from "../../../theme/theme";
import { errorResponseHandler, successResponseHandler } from "../../../utils/reposeAlert";
import MeetingDatePicker from "../../Meeting/MeetingDatePicker";
import SharedButton from "../../Shared/SharedButton";

const AskForRescheduleModal = ({ openResModal, closeResModalHandler, meetingId }) => {
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      xs: "100%",
      md: "45rem",
    },
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
    pt: 5,
  };
  const [startTime, setStartTime] = useState(null);
  const [startTimeToSubmit, setStartTimeToSubmit] = useState(null);
  const [disabledAddMeeting, setDisabledAddMeeting] = useState(false);

  const handleStartTimeChange = (date) => {
    const startDateCopy = new Date(Number(date));
    const offset = startDateCopy.getTimezoneOffset();
    const ammanOffset = 180;
    startDateCopy.setMinutes(startDateCopy.getMinutes() - offset);
    startDateCopy.setMinutes(startDateCopy.getMinutes() - ammanOffset);
    setStartTimeToSubmit(startDateCopy);
    setStartTime(date);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    askForResHandler();
  };

  const askForResHandler = async () => {
    setDisabledAddMeeting(true);
    await askForRescheduleApi(meetingId, startTimeToSubmit.toISOString())
      .then((response) => {
        if (response.status === 200) {
          successResponseHandler(`Updated`, response.data.message);
          closeResModalHandler();
        }
      })
      .catch((error) => {
        const msg = error.response.data;
        errorResponseHandler(`Failed`, msg);
      })
      .finally(() => {
        setStartTime("");
        setDisabledAddMeeting(false);
      });
  };

  const handleUpdate = () => {};

  return (
    <Modal
      open={openResModal}
      onClose={closeResModalHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        zIndex: 10,
      }}
    >
      <Paper elevation={3} sx={modalStyle}>
        <Box style={{ position: "absolute", top: "0.5rem", left: "0.5rem" }}>
          <IconButton
            onClick={closeResModalHandler}
            sx={{
              padding: 0,
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography
          variant="h4"
          sx={{
            color: theme.palette.primary.main,
            fontWeight: "bold",
            marginBottom: "2rem",
          }}
        >
          Ask For Reschedule
        </Typography>
        <form onSubmit={submitHandler}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <MeetingDatePicker label="Choose the Time" handleChange={handleStartTimeChange} value={startTime} />
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  borderTop: `1px solid ${theme.palette.primary.main}`,
                  paddingTop: "1rem",
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button variant="outlined" color="secondary" onClick={closeResModalHandler}>
                  Cancel
                </Button>
                <SharedButton
                  color="primary"
                  text="Send"
                  onAction={handleUpdate}
                  disabled={disabledAddMeeting}
                  type="submit  "
                />
              </Box>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Modal>
  );
};

export default AskForRescheduleModal;