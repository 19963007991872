export const formatDate = (startDate, endDate) => {
  const startDateTime = new Date(startDate);
  const endDateTime = new Date(endDate);

  const Offset = 180;
  startDateTime.setMinutes(startDateTime.getMinutes() + Offset);
  endDateTime.setMinutes(endDateTime.getMinutes() + Offset);

  startDate = startDateTime.toISOString();
  endDate = endDateTime.toISOString();

  startDate = startDate.replace("T", " ");
  endDate = endDate.replace("T", " ");
  startDate = startDate.replace(":00.000Z", "");
  endDate = endDate.replace(":00.000Z", " ");

  const s = endDate.split(" ");
  endDate = s[1];
  const s2 = s[1].split(":");
  if (parseInt(s2[0]) > 12) {
    endDate = parseInt(s2[0]) - 12;
    endDate = endDate + ":" + s2[1] + " PM";
  } else if (parseInt(s2[0]) === 12) {
    endDate = s2[0] + ":" + s2[1] + " PM";
  } else {
    endDate = s2[0] + ":" + s2[1] + " AM";
  }
  const F = startDate.split(" ");
  const timeString = F[1].split(":");
  if (parseInt(timeString[0]) > 12) {
    const hourString = parseInt(timeString[0]) - 12;
    startDate = F[0] + " " + hourString + ":" + timeString[1] + " PM";
  } else if (parseInt(timeString[0]) === 12) {
    startDate = F[0] + " " + timeString[0] + ":" + timeString[1] + " PM";
  } else {
    startDate = F[0] + " " + timeString[0] + ":" + timeString[1] + " AM";
  }

  // console.log("startDate", startDate);
  // console.log("endDate", endDate);
  // const Finalformat = (date1, date2) => {
  //   const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  //   const month = months[date1.getMonth()];
  //   const day = date1.getDate();
  //   const hourOne = date1.getHours();
  //   const hourTwo = date2.getHours();
  //   const minutesOne = date1.getMinutes();
  //   const minutesTwo = date2.getMinutes();
  //   const ampmOne = hourOne >= 12 ? "PM" : "AM";
  //   const ampmTwo = hourTwo >= 12 ? "PM" : "AM";
  //   const hourOne12 = hourOne % 12 || 12;
  //   const hourTwo12 = hourTwo % 12 || 12;
  //   const minutesOneFormatted = minutesOne < 10 ? `0${minutesOne}` : minutesOne;
  //   const minutesTwoFormatted = minutesTwo < 10 ? `0${minutesTwo}` : minutesTwo;

  //   return `${month} ${day}, ${hourOne12}:${minutesOneFormatted} ${ampmOne} - ${hourTwo12}:${minutesTwoFormatted} ${ampmTwo}`;
  // };




  // if (startDate && endDate) {
  //   return Finalformat(startDate, startDate);
  // }

  return `${startDate} - ${endDate}`;

};