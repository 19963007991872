import { useTheme } from "@emotion/react";
import { Button } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";

function ArrowIcon() {
  return <img src="/assets/images/arrow.svg" alt="arrow" width="12" />;
}

const LoginButton = () => {
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      justifyContent="end"
      sx={{
        marginTop: "1.5rem",
      }}
    >
      <Button
        variant="contained"
        color="primary"
        type="submit"
        endIcon={<ArrowIcon />}
        sx={{
          borderRadius: "0",
          padding: ".2rem 1rem",
          fontSize: "1.6rem",
          minWidth: "10rem",
          textTransform: "none",
          justifyContent: "space-around",
          "&:hover": {
            backgroundColor: theme.palette.primary.main,
          },
        }}
      >
        Login
      </Button>
    </Stack>
  );
};

export default LoginButton;
