import { Typography } from "@mui/material";
import React from "react";
import theme from "../../theme/theme";

const MeetingDescription = ({ description }) => {
  return (
    <Typography variant="body1" sx={{ color: theme.palette.gray.main, marginBottom: "1rem" }}>
      {description}
    </Typography>
  );
};

export default MeetingDescription;
