import { Typography } from "@mui/material";
import React from "react";
import theme from "../../theme/theme";

const MeetingOrganizer = ({ organizer, user, meeting }) => {
  return (
    <Typography variant="subtitle1" gutterBottom sx={{ color: theme.palette.gray.main }}>
      Meeting organizer: {user.id === meeting?.owner_id ? "You" : organizer}
    </Typography>
  );
};

export default MeetingOrganizer;
