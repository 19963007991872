import { useTheme } from "@emotion/react";
import { Grid, TextField } from "@mui/material";

import React from "react";

const LoginInput = ({ type, name, label, placeholder, value, onChange }) => {
  const theme = useTheme();

  return (
    <Grid container alignItems="center">
      <Grid item xs={12}>
        <TextField
          margin="normal"
          type={type}
          required
          fullWidth
          id={name}
          label={label}
          name={name}
          autoComplete={name}
          placeholder={placeholder}
          autoFocus
          InputLabelProps={{
            sx: {
              fontSize: "1rem",
            },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "0",
              padding: ".2rem 1rem",
              height: "3.2rem",
              "& input": {
                fontSize: "1.2rem",
                color: theme.palette.black.main,
              },
              "& fieldset": {
                borderColor: theme.palette.secondary.main,
              },
              "&:hover fieldset": {
                borderColor: theme.palette.secondary.main,
              },
              "&.Mui-focused fieldset": {
                borderColor: "theme.palette.secondary.main",
              },
            },
          }}
          value={value}
          onChange={onChange}
        />
      </Grid>
    </Grid>
  );
};

export default LoginInput;
