import { LOGIN_SUCCESS, LOGOUT_SUCCESS } from "../actions/auth";
import jwt_decode from "jwt-decode";
const accessToken = localStorage.getItem("accessToken")
  ? localStorage.getItem("accessToken")
  : null;
const refreshToken = localStorage.getItem("refreshToken")
  ? localStorage.getItem("refreshToken")
  : null;

const initialState = {
  tokens: {
    accessToken,
    refreshToken,
  },
  user: accessToken ? jwt_decode(accessToken) : null,
  isAuthenticated: accessToken ? true : false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        tokens: action.payload,
        isAuthenticated: true,
        user: jwt_decode(action.payload.accessToken),
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        tokens: {
          accessToken: null,
          refreshToken: null,
        },
        user: null,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};

export default authReducer;
