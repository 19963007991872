import { TextField } from "@mui/material";
import React from "react";

const MeetingTextarea = ({ description, setDescription }) => {
  return (
    <TextField
      label="Description"
      variant="outlined"
      multiline
      rows={5}
      fullWidth
      required
      placeholder="Description"
      value={description}
      onChange={(e) => setDescription(e.target.value)}
    />
  );
};

export default MeetingTextarea;
