import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3FBCD3",
      light: "rgb(5 61 100 / 80%)",
    },
    secondary: {
      main: "#e5b767",
    },
    white: {
      main: "#ffffff",
    },
    black: {
      main: "#000000",
    },
    gray: {
      main: "#4d4d4d",
    },
    warning: {
      main: "#f9a825",
    },
    success: {
      main: "#4caf50",
    },
    error: {
      main: "#f44336",
    },
  },
  typography: {
    fontFamily: "'Nanum Gothic',sans-serif",
  },
});

export default theme;
