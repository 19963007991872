import { Typography } from "@mui/material";
import React from "react";
import theme from "../../theme/theme";

const MeetingDate = ({ date }) => {
  return (
    <Typography variant="subtitle1" gutterBottom sx={{ color: theme.palette.gray.main }}>
      Date: {date}
    </Typography>
  );
};

export default MeetingDate;
