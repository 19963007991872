import { Typography } from "@mui/material";
import { useState , useEffect } from "react";
import { Box } from "@mui/system";
import React from "react";
import theme from "../../theme/theme";
import "../../theme/style.css"
const MeetingStatus = ({ status }) => {
  const [flag , setflag] = useState(false);

  useEffect(() => {
    if(status === "cancelled")
    {
      setflag(true);
    }
  }, [])
  

  const checkMeetingStatusColor = () => {
    switch (status) {
      case "cancelled":
        return theme.palette.error.main;
      case "finished":
        return theme.palette.success.main;
      case "pending":
        return theme.palette.warning.main;
      default:
        return theme.palette.warning.main;
    }
  };
  return (
    <>
    <div  className={flag ? '' : 'disFlagNone'}>  
    <Box
      sx={{
        position: "absolute",
        top: "1rem",
        right: "1rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: `1px solid ${theme.palette.gray.main}`,
        borderRadius: ".2rem",
        padding: "0rem .2rem",
      }}
    >
      <Box
        sx={{
          backgroundColor: checkMeetingStatusColor(),
          marginRight: 0.5,
          width: 12,
          height: 12,
          borderRadius: "50%",
        }}
      ></Box>
      <Typography variant="caption">{status}</Typography>
    </Box>
    </div>
    </>
  );
};

export default MeetingStatus;
