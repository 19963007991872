import { Autocomplete, TextField } from "@mui/material";
import React from "react";

const MeetingSingleSelection = ({ options, selected, setSelected,disabled = false }) => {
  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select Companies"
          variant="outlined"
          fullWidth
          required
        />
      )}
      onChange={(event, newValue) => {
        setSelected(newValue);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={selected}
      disabled={disabled || false}
    />
  );
};

export default MeetingSingleSelection;
