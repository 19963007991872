import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Modal, Paper } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { acceptDeclineMeetingApi, askForRescheduleApi } from "../../../API/schedule";
import { acceptDeniedMeeting } from "../../../store/actions/schedule";
import { formatDate } from "../../../utils/formateDate";
import { errorResponseHandler, successResponseHandler } from "../../../utils/reposeAlert";
import InviteesList from "../../Invitees/InviteesList";
import MeetingActions from "../../Meeting/MeetingActions";
import MeetingCompanyName from "../../Meeting/MeetingCompanyName";
import MeetingDate from "../../Meeting/MeetingDate";
import MeetingDescription from "../../Meeting/MeetingDescription";
import MeetingOrganizer from "../../Meeting/MeetingOrganizer";
import MeetingStatus from "../../Meeting/MeetingStatus";
import MeetingTitle from "../../Meeting/MeetingTitle";

const MeetingModal = ({
  openMeetingModal,
  closeMeetingModalHandler,
  meeting,
  toggleRescheduleModal,
  showShowAskForRescheduleModalHandler,
}) => {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      xs: "100%",
      md: "35rem",
    },
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
    pt: 5,
  };

  const checkOwnMeeting = () => {
    if (meeting.owner_id === user.id) {
      return true;
    }
    return false;
  };

  const acceptDeclineMeetingHandler = async (answer) => {
    await acceptDeclineMeetingApi(answer, meeting.id)
      .then((response) => {
        if (response.status === 200) {
          successResponseHandler(`Updated`, response.data.message);
          dispatch(acceptDeniedMeeting(meeting.id, user.id, response.data.data.status));
          closeMeetingModalHandler();
        }
      })
      .catch((error) => {
        const msg = error.response.data;
        errorResponseHandler(`Failed`, msg);
      })
      .finally({});
  };

  const rescheduleMeetingHandler = () => {
    toggleRescheduleModal(true);
  };

  const askForRescheduleHandler = async () => {
    showShowAskForRescheduleModalHandler(true, meeting.id);
  };

  const checkDisableButton = () => {
    if (meeting.meeting_status === "pending") {
      return false;
    }
    return true;
  };

  return (
    <Modal
      open={openMeetingModal}
      onClose={closeMeetingModalHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        zIndex: 10,
      }}
    >
      <Paper elevation={3} sx={modalStyle}>
        <Box style={{ position: "absolute", top: "0.5rem", left: "0.5rem" }}>
          <IconButton
            onClick={closeMeetingModalHandler}
            sx={{
              padding: 0,
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        {/* <MeetingStatus status={meeting.meeting_status} /> */}
        <Box sx={{ position: "relative" }}>
          <MeetingTitle title={meeting.title} />
          <MeetingDate date={`${formatDate(meeting.start_date, meeting.end_date)}`} />
          <MeetingCompanyName company={meeting.company} />
          {user && (
            <MeetingOrganizer
              organizer={`${meeting.user.first} ${meeting.user.last} - (${meeting.user.company}) - (${meeting.user.position})`}
              user={user}
              meeting={meeting}
            />
          )}
          <MeetingDescription
            description={
              meeting.description.length > 160 ? `${meeting.description.substring(0, 160)}...` : meeting.description
            }
          />
          <InviteesList invitees={meeting.invitations} />
          <MeetingActions
            ownMeeting={checkOwnMeeting}
            acceptDeclineMeetingHandler={acceptDeclineMeetingHandler}
            askForRescheduleHandler={askForRescheduleHandler}
            rescheduleMeetingHandler={rescheduleMeetingHandler}
            disabledButton={checkDisableButton}
            closeMeetingModalHandler={closeMeetingModalHandler}
          />
        </Box>
      </Paper>
    </Modal>
  );
};

export default MeetingModal;
