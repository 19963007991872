import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { visuallyHidden } from "@mui/utils";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as XLSX from "xlsx";
import theme from "../../../theme/theme";
import FilterTable from "./FilterTable";

function MeetingTable() {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState("calories");

	const [allSchedules, setAllSchedules] = useState([]);
	const [filteredSchedules, setFilteredSchedules] = useState([]);

	const { schedules } = useSelector((state) => state.schedules || []);

	useEffect(() => {
		if (schedules.length) {
			const allSchedules = schedules.map((schedule) => {
				let start_date = "";
				let end_date = "";
				const ammanStartDate = schedule?.start_date ? new Date(schedule?.start_date) : "";
				const ammanEndDate = schedule?.end_date ? new Date(schedule?.end_date) : "";
				const ammanOffset = 180;
				if (ammanStartDate) {
					ammanStartDate.setMinutes(ammanStartDate.getMinutes() + ammanOffset);
					start_date = ammanStartDate.toISOString();
				}
				if (ammanEndDate) {
					ammanEndDate.setMinutes(ammanEndDate.getMinutes() + ammanOffset);
					end_date = ammanEndDate.toISOString();
				}
				const { title, id, meeting_status, user, invitations, company } = schedule;
				const { first, last, position, company: ownerCompany } = user;

				const invitationsList = invitations.map((invitation) => {
					const { status, user } = invitation;
					const { first, last, position } = user;
					return { status, name: `${first} ${last}`, position };
				});

				return {
					id,
					title,
					start_end_date: `${start_date} - ${end_date}`,
					meeting_status: meeting_status,
					company,
					owner_name: `${first} ${last} (${position}) - ${ownerCompany}`,
					invitations_list: invitationsList,
				};
			});
			setAllSchedules(allSchedules);
			setFilteredSchedules(allSchedules);
		}
	}, [schedules]);

	const checkMeetingStatusColor = (status) => {
		switch (status) {
			case "cancelled":
				return theme.palette.error.main;
			case "finished":
				return theme.palette.success.main;
			case "pending":
				return theme.palette.warning.main;
			default:
				return theme.palette.warning.main;
		}
	};

	const checkMeetingUserStatusColor = (status) => {
		switch (status) {
			case "pending":
				return theme.palette.warning.main;
			case "accepted":
				return theme.palette.success.main;
			case "declined":
				return theme.palette.error.main;
			default:
				return theme.palette.warning.main;
		}
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const descendingComparator = (a, b, orderBy) => {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return 0;
	};

	const getComparator = (order, orderBy) => {
		return order === "desc"
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	};

	const stableSort = (array, comparator) => {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) return order;
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	};
	const handleDownloadToExcel = () => {
		const flattenedSchedules = filteredSchedules.map((schedule) => {
			return {
				...schedule,
				invitations_list: schedule.invitations_list.map((item) => `${item.name} (${item.status})`).join(", "),
			};
		});
		const worksheet = XLSX.utils.json_to_sheet(flattenedSchedules);
		const workbook = XLSX.utils.book_new();

		// Set the width of the date column to 20
		worksheet["!cols"] = [{}, {}, { width: 50 }, {}, {}, { width: 20 }, { width: 50 }];

		XLSX.utils.book_append_sheet(workbook, worksheet, "Schedule");
		XLSX.writeFile(workbook, "schedule.xlsx");
	};

	const EnhancedTableHead = (props) => {
		const { order, orderBy, onRequestSort } = props;
		const createSortHandler = (property) => (event) => {
			onRequestSort(event, property);
		};

		return (
			<TableHead>
				<TableRow>
					{columns.map((column) => (
						<TableCell
							key={column.id}
							align={column.align}
							style={{ minWidth: column.minWidth }}
							sortDirection={orderBy === column.id ? order : false}
						>
							<TableSortLabel
								active={orderBy === column.id}
								direction={orderBy === column.id ? order : "asc"}
								onClick={createSortHandler(column.id)}
							>
								{column.label}
								{orderBy === column.id ? (
									<Box component="span" sx={visuallyHidden}>
										{order === "desc" ? "sorted descending" : "sorted ascending"}
									</Box>
								) : null}
							</TableSortLabel>
						</TableCell>
					))}
				</TableRow>
			</TableHead>
		);
	};

	const columns = [
		{ id: "id", label: "ID", minWidth: 25 },
		{ id: "title", label: "Title", minWidth: 140 },
		{ id: "start_end_date", label: "Date", minWidth: 140 },
		{ id: "meeting_status", label: "Status", minWidth: 140 },
		{ id: "company", label: "Company", minWidth: 140 },
		{ id: "owner_name", label: "Owner", minWidth: 140 },
		{ id: "invitations_list", label: "Invitations", minWidth: 200 },
	];

	const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredSchedules.length - page * rowsPerPage);
	return (
		// add filter
		<Paper sx={{ width: "100%", overflow: "hidden" }}>
			<FilterTable allSchedules={allSchedules} setFilteredSchedules={setFilteredSchedules} />
			{filteredSchedules.length ? (
				<>
					<TableContainer sx={{ maxHeight: 440 }}>
						<Table stickyHeader aria-label="sticky table">
							<EnhancedTableHead
								columns={columns}
								order={order}
								orderBy={orderBy}
								onRequestSort={handleRequestSort}
								rowCount={filteredSchedules.length}
							/>
							<TableBody>
								{stableSort(filteredSchedules, getComparator(order, orderBy))
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row) => {
										return (
											<TableRow hover role="checkbox" tabIndex={-1} key={`row-${row.id}`}>
												{columns.map((column) => {
													const value = row[column.id];
													return (
														<TableCell key={`column-${column.id}`} align={column.align}>
															{column.id === "meeting_status" ? (
																<div
																	style={{
																		position: "relative",
																	}}
																>
																	<Box
																		sx={{
																			display: "flex",
																			alignItems: "center",
																		}}
																	>
																		<Box
																			sx={{
																				backgroundColor: checkMeetingStatusColor(value),
																				marginRight: 0.5,
																				width: 12,
																				height: 12,
																				borderRadius: "50%",
																			}}
																		></Box>
																		<Typography variant="caption">{value}</Typography>
																	</Box>
																</div>
															) : column.id === "invitations_list" ? (
																<div>
																	{value.map((item, index) => (
																		<div
																			style={{
																				marginBottom: value.indexOf(item) !== value.length - 1 && "0.5rem",
																			}}
																			key={`invitations_list-${index}`}
																		>
																			<span>{item.name}</span>
																			<span style={{ marginLeft: "0.5rem" }}>({item.position}) /</span>
																			<span style={{ marginLeft: "0.5rem" }}>{item.status}</span>
																		</div>
																	))}
																</div>
															) : column.id === "start_end_date" ? (
																<div>
																	<span>{value.split(" - ")[0].split("T")[0].split("-").reverse().join("/")}</span>
																	{" ("}
																	<span>{value.split(" - ")[0].split("T")[1].split(":").slice(0, 2).join(":")}</span>
																	{" - "}
																	<span>{value.split(" - ")[1].split("T")[1].split(":").slice(0, 2).join(":")}</span>
																	{")"}
																</div>
															) : (
																value
															)}
														</TableCell>
													);
												})}
											</TableRow>
										);
									})}
								{emptyRows > 0 && (
									<TableRow style={{ height: 53 * emptyRows }}>
										<TableCell colSpan={6} />
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>

					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							padding: "0.5rem 0",
						}}
					>
						<Button
							variant="contained"
							color="primary"
							onClick={handleDownloadToExcel}
							sx={{
								marginLeft: "0.5rem",
							}}
						>
							Download as Excel
						</Button>
						<TablePagination
							rowsPerPageOptions={[5, 10, 25]}
							component="div"
							count={filteredSchedules.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</Box>
				</>
			) : (
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "100%",
					}}
				>
					<Typography variant="h6">No data</Typography>
				</Box>
			)}
		</Paper>
	);
}

export default MeetingTable;