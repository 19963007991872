import { Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import theme from "../../../theme/theme";

const DashboardHeader = () => {
  return (
    <Paper elevation={3}>
      <Box p={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography
          variant="h4"
          sx={{
            color: theme.palette.primary.main,
          }}
        >
          Meetings List
        </Typography>
      </Box>
    </Paper>
  );
};

export default DashboardHeader;
