import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import PersonIcon from "@mui/icons-material/Person";
import theme from "../../theme/theme";

const InviteesList = ({ invitees }) => {
  return (
    <div>
      <Typography
        variant="h6"
        sx={{
          color: theme.palette.black.main,
          marginTop: "1rem",
          fontSize: "1.2rem",
        }}
      >
        List of Invitees
      </Typography>
      <List sx={{ listStyle: "none" }}>
        {invitees.length
          ? invitees.map((invitee) => (
              <ListItem
                sx={{ width: "100%", padding: "0", display: "flex" }}
                key={invitee.id}
              >
                <ListItemAvatar
                  sx={{
                    minWidth: "auto",
                  }}
                >
                  <Avatar
                    sx={{
                      m: "0",
                      width: 25,
                      height: 25,
                      backgroundColor: "#d1d1d1",
                    }}
                  >
                    <PersonIcon
                      sx={{
                        fontSize: "1rem",
                      }}
                    />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={`${invitee.user.first} ${invitee.user.last} - (${invitee.user.position}) / `}
                  secondary={invitee.status}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    "& .MuiListItemText-primary": {
                      color: theme.palette.gray.main,
                    },
                    "& .MuiListItemText-secondary": {
                      color: theme.palette.gray.main,
                      position: "relative",
                    },
                    "& .MuiTypography-root": {
                      marginLeft: "0.5rem",
                    },
                  }}
                />
              </ListItem>
            ))
          : null}
      </List>
    </div>
  );
};

export default InviteesList;
