import { TextField } from "@mui/material";
import React from "react";

const MeetingInput = ({ label, title, setTitle }) => {
  return (
    <TextField
      label={label}
      variant="outlined"
      fullWidth
      required
      value={title}
      onChange={(e) => setTitle(e.target.value)}
    />
  );
};

export default MeetingInput;
