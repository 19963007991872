import { Box, Button, ButtonGroup, Divider } from "@mui/material";
import React from "react";
import theme from "../../theme/theme";
import SharedButton from "../Shared/SharedButton";

const MeetingActions = ({
  ownMeeting,
  acceptDeclineMeetingHandler,
  askForRescheduleHandler,
  rescheduleMeetingHandler,
  disabledButton,
  closeMeetingModalHandler,
}) => {
  return (
    <Box
      sx={{
        borderTop: `1px solid ${theme.palette.primary.main}`,
        paddingTop: "1rem",
      }}
    >
      <ButtonGroup
        variant="contained"
        aria-label="meeting actions"
        sx={{
          justifyContent: "flex-end",
          display: "flex",
          alignItems: "center",
          boxShadow: "none",
          margin: "0 -8px",
        }}
      >
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => closeMeetingModalHandler()}
          sx={{
            borderRadius: "4px !important",
            border: "1px solid #e5b767 !important",
          }}
        >
          Cancel
        </Button>
        {ownMeeting() ? (
          <SharedButton
            color="primary"
            text="Edit"
            onAction={rescheduleMeetingHandler}
            disabled={disabledButton()}
          />
        ) : (
          <>
            <SharedButton
              color="error"
              text="Decline"
              onAction={() => acceptDeclineMeetingHandler("declined")}
              disabled={disabledButton()}
            />
            <Divider orientation="vertical" flexItem />

            <SharedButton
              color="primary"
              text="Ask for reschedule"
              onAction={askForRescheduleHandler}
              disabled={disabledButton()}
            />
            <Divider orientation="vertical" flexItem />

            <SharedButton
              color="primary"
              text="Accept"
              onAction={() => acceptDeclineMeetingHandler("accepted")}
              disabled={disabledButton()}
            />
          </>
        )}
      </ButtonGroup>
    </Box>
  );
};

export default MeetingActions;
