import { Grid, Modal, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import theme from "../../theme/theme";
import SharedButton from "../Shared/SharedButton";
import MeetingInput from "../Meeting/MeetingInput";

const ChangePasswordModal = ({
  openModal,
  oldPassword,
  setOldPassword,
  setNewPassword,

  newPassword,
  toggleModal,
  changePasswordHandler,
}) => {
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      xs: "100%",
      md: "35rem",
    },
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
  };

  const submitHandler = (e) => {
    e.preventDefault();
    changePasswordHandler();
  };

  return (
    <Modal
      open={openModal}
      onClose={() => toggleModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        zIndex: 10,
      }}
    >
      <Paper elevation={3} sx={modalStyle}>
        <Typography
          variant="h4"
          sx={{
            color: theme.palette.primary.main,
            fontWeight: "bold",
            marginBottom: "2rem",
          }}
        >
          Change Password
        </Typography>
        <form onSubmit={submitHandler}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MeetingInput
                label="Old Password"
                title={oldPassword}
                setTitle={setOldPassword}
              />
            </Grid>
            <Grid item xs={12}>
              <MeetingInput
                label="New Password"
                title={newPassword}
                setTitle={setNewPassword}
              />
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  borderTop: `1px solid ${theme.palette.primary.main}`,
                  paddingTop: "1rem",
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <SharedButton
                  color="primary"
                  text="Submit"
                  type="submit"
                  disabled={false}
                />
              </Box>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Modal>
  );
};

export default ChangePasswordModal;
