import {
  ACCEPT_DENIED_MEETING,
  ADD_MEETINGS_SUCCESS,
  ADD_MEETING_SUCCESS,
  GET_USER_SCHEDULE_SUCCESS,
  SET_ALL_SCHEDULES_SUCCESS,
  SET_MEETING_SUCCESS,
  UPDATE_MEETING_SUCCESS,
} from "../actions/schedule";

const initialState = {
  meetings: [],
  meeting: null,
  schedules: [],
};

const scheduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_SCHEDULES_SUCCESS:
      return {
        ...state,
        schedules: action.payload,
      };

    case GET_USER_SCHEDULE_SUCCESS:
      return {
        ...state,
        meetings: action.payload,
      };
    case ADD_MEETINGS_SUCCESS:
    case ADD_MEETING_SUCCESS:
      return {
        ...state,
        meetings: [...state.meetings, action.payload],
      };

    case SET_MEETING_SUCCESS:
      return {
        ...state,
        meeting: action.payload,
      };
    case UPDATE_MEETING_SUCCESS:
      return {
        ...state,
        meetings: state.meetings.map((meeting) => {
          if (meeting.id === action.payload.id) {
            return action.payload;
          }
          return meeting;
        }),
        meeting: action.payload,
      };

    case ACCEPT_DENIED_MEETING:
      const { status, userId, meetingId } = action.payload;
      if (status === "declined") {
        return {
          ...state,
          meetings: state.meetings.filter(
            (meeting) => meeting.id !== meetingId
          ),
        };
      }
      return {
        ...state,
        meetings: state.meetings.map((meeting) => {
          if (meeting.id === meetingId) {
            return {
              ...meeting,
              invitations: meeting.invitations.map((invitation) => {
                if (invitation.user_id === userId) {
                  return {
                    ...invitation,
                    status,
                  };
                }
                return invitation;
              }),
            };
          }
          return meeting;
        }),
      };

    default:
      return state;
  }
};

export default scheduleReducer;
