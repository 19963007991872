import { axiosInstance } from "../utils/axios";

// Login API
export const loginApi = async ({ profileId, password }) => {
  return await axiosInstance.post("/auth/login", {
    profile_id: profileId,
    password,
  });
};

// RefreshTokens API
export const refreshTokensApi = async (refreshToken) => {
  return await axiosInstance.post("/auth/refresh-token", {
    headers: {
      Authorization: `Bearer ${refreshToken}`,
    },
  });
};

// Update Password API
export const updatePasswordApi = async ({ oldPassword, newPassword }) => {
  return await axiosInstance.put("/auth/update-password", {
    oldPassword,
    newPassword,
  });
};
