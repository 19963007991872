import { Button, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import theme from "../../../theme/theme";
import AddIcon from "@mui/icons-material/Add";
import AddMeetingModal from "./AddMeetingModal";
import pdf from "../../../pdf/Booths - meeting rooms 4-4-2023.pdf" 

const AddMeeting = ({ getMeetings }) => {
  const [openAddMeetingModal, setOpenAddMeetingModal] = useState(false);
  const toggleAddMeetingModalHandler = (open) => {
    setOpenAddMeetingModal(open);
  };

 
  return (
    <Paper elevation={3}>
      <Box
        p={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography
          variant="h4"
          sx={{
            color: theme.palette.primary.main,
            fontSize: {
              xs: "1.4rem",
              md: "2.125rem",
            }
          }}
        >
          Meetings List
        </Typography>
        <Box>
        <a href={pdf} download style={{textDecoration:"none"}}>
          <Button
            variant="contained"
            
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.white.main,
              "&:hover": {
                backgroundColor: theme.palette.primary.main,
              },
              marginRight:"15px",
            }}
          
          >
            Conference Venue
          </Button>
        </a>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.white.main,
            "&:hover": {
              backgroundColor: theme.palette.primary.main,
            },
          }}
          onClick={() => toggleAddMeetingModalHandler(true)}
        >
          Add Meeting
        </Button>
        </Box>
      </Box>
      <AddMeetingModal
        openMeetingModal={openAddMeetingModal}
        toggleMeetingModalHandler={toggleAddMeetingModalHandler}
        getMeetings={getMeetings}
      />
    </Paper>
  );
};

export default AddMeeting;
