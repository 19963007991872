import { Button } from "@mui/material";
import React from "react";

const SharedButton = ({ color, text, type, onAction, disabled, variant }) => {
  return (
    <Button
      type={type ? type : "button"}
      color={color}
      variant={variant ? variant : "contained"}
      sx={{
        margin: "0 8px",
        borderRadius: "4px !important",
        border: "none !important",
      }}
      onClick={onAction}
      disabled={Boolean(disabled)}
    >
      {text}
    </Button>
  );
};

export default SharedButton;
