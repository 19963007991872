import { axiosInstance } from "../utils/axios";

// Get user schedule
export const getUserScheduleApi = async (page, limit = 12) => {
  return await axiosInstance.get(`/schedules/user?page=${page}&limit=${limit}`);
};

// accept decline meeting API
export const acceptDeclineMeetingApi = async (answer, meetingId) => {
  return await axiosInstance.post(`invitations/acceptDecline`, {
    status: answer,
    schedule_id: meetingId,
  });
};

// Reschedule API
export const rescheduleMeetingApi = async (meetingId, data) => {
  return await axiosInstance.put(`schedules/${meetingId}`, {
    ...data,
  });
};

// Ask For Reschedule API
export const askForRescheduleApi = async (meetingId, date) => {
  return await axiosInstance.post(`schedules/askForReschedule`, {
    schedule_id: meetingId,
    date,
  });
};

// Add Schedule API
export const addScheduleApi = async (data) => {
  return await axiosInstance.post(`schedules`, data);
};

// Get all schedule
export const getAllScheduleApi = async () => {
  return await axiosInstance.get(`/schedules`);
};
