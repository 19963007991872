import theme from "./theme";

const globalStyles = {
  html: {
    height: "100%",
  },
  body: {
    margin: 0,
    padding: 0,
    height: "100%",
    fontFamily: theme.typography.fontFamily,
    backgroundColor: theme.palette.white.main,
  },
  "*": {
    boxSizing: "border-box",
  },
  "#root": {
    height: "100%",
    fontSize: "1.6rem",
  },

  h1: {
    fontSize: "3rem",
    fontWeight: "bold",
  },
  h2: {
    fontSize: "2.5rem",
    fontWeight: "bold",
  },
  h3: {
    fontSize: "2rem",
    fontWeight: "bold",
  },
  h4: {
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  h5: {
    fontSize: "1.2rem",
    fontWeight: "bold",
  },
  h6: {
    fontSize: "1rem",
    fontWeight: "bold",
  },
};

export default globalStyles;
