export const GET_USER_SCHEDULE_SUCCESS = "GET_USER_SCHEDULE_SUCCESS";
export const ADD_MEETINGS_SUCCESS = "ADD_MEETINGS_SUCCESS";
export const ADD_MEETING_SUCCESS = "ADD_MEETING_SUCCESS";
export const SET_MEETING_SUCCESS = "GET_MEETING_SUCCESS";
export const ACCEPT_DENIED_MEETING = "ACCEPT_DENIED_MEETING";
export const UPDATE_MEETING_SUCCESS = "UPDATE_MEETING_SUCCESS";

export const SET_ALL_SCHEDULES_SUCCESS = "SET_ALL_SCHEDULES_SUCCESS";

export const getUserScheduleSuccess = (payload) => ({
  type: GET_USER_SCHEDULE_SUCCESS,
  payload,
});

export const addMeetingsSuccess = ({ meetings }) => ({
  type: ADD_MEETINGS_SUCCESS,
  payload: meetings,
});

export const addMeetingSuccess = ({ meeting }) => ({
  type: ADD_MEETING_SUCCESS,
  payload: meeting,
});

export const setMeetingSuccess = (meeting) => {
  return {
    type: SET_MEETING_SUCCESS,
    payload: meeting,
  };
};

export const acceptDeniedMeeting = (meetingId, userId, status) => ({
  type: ACCEPT_DENIED_MEETING,
  payload: {
    meetingId,
    userId,
    status,
  },
});

export const updateMeetingSuccess = (meeting) => ({
  type: UPDATE_MEETING_SUCCESS,
  payload: meeting,
});

export const setAllSchedulesSuccess = (schedules) => ({
  type: SET_ALL_SCHEDULES_SUCCESS,
  payload: schedules,
});
