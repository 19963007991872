import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserScheduleApi } from "../../../API/schedule";

import { setMeetingSuccess as setMeetingAction } from "../../../store/actions/schedule";
import { errorResponseHandler } from "../../../utils/reposeAlert";
import AskForRescheduleModal from "./AskForRescheduleModal";
import MeetingBox from "./MeetingBox";
import MeetingModal from "./MeetingModal";
import RescheduleModal from "./RescheduleModal";
const Meetings = () => {
  const dispatch = useDispatch();
  const [openMeetingModal, setOpenMeetingModal] = useState(false);
  const meetings = useSelector((state) => state.schedules.meetings);
  const [openRescheduleModal, setOpenRescheduleModal] = useState(false);
  const selectedMeeting = useSelector((state) => state.schedules.meeting);
  const [showAskForRescheduleModal, setShowAskForRescheduleModal] =
    useState(false);
  const closeMeetingModalHandler = () => {
    setOpenMeetingModal(false);
    clearSearchParams();
  };
  const [selectedMeetingId, setSelectedMeetingId] = useState(null);

  const openMeetingModalHandler = (meetingId) => {
    const meeting = meetings.find((meeting) => meeting.id === meetingId);
    dispatch(setMeetingAction(meeting));
    setOpenMeetingModal(true);
  };

  const toggleRescheduleModal = (open) => {
    setOpenMeetingModal(false);
    setOpenRescheduleModal(open);
  };

  const clearSearchParams = () => {
    const newUrl = window.location.pathname;
    window.history.replaceState(null, "", newUrl);
  };

  const askForRescheduleHandler = (open, meetingId) => {
    setOpenMeetingModal(false);
    setShowAskForRescheduleModal(true);
    setSelectedMeetingId(meetingId);
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const meetingId = searchParams.get("meeting");
    if (meetingId) {
      openSelectedMeetingModal(meetingId);
    }
  }, [meetings, dispatch]);

  const openSelectedMeetingModal = async (meetingId) => {
    await getUserScheduleApi(1, 100000000)
      .then((response) => {
        if (response.status === 200) {
          const meetings = response.data.data.rows;
          const meeting = meetings.find((meeting) => meeting.id === +meetingId);
          if (meeting) {
            dispatch(setMeetingAction(meeting));
            setOpenMeetingModal(true);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        const msg = error.response.data;
        errorResponseHandler("Login Failed", msg);
      })
      .finally({});
  };

  return (
    <>
      <Grid container spacing={2}>
        {meetings.length
          ? meetings.map((meeting) => (
              <MeetingBox
                key={meeting.id}
                meeting={meeting}
                openMeetingModal={openMeetingModalHandler}
              />
            ))
          : null}
      </Grid>
      {selectedMeeting && (
        <>
          <MeetingModal
            openMeetingModal={openMeetingModal}
            closeMeetingModalHandler={closeMeetingModalHandler}
            meeting={selectedMeeting}
            toggleRescheduleModal={toggleRescheduleModal}
            showShowAskForRescheduleModalHandler={askForRescheduleHandler}
          />
        </>
      )}
      {selectedMeeting && (
        <RescheduleModal
          openModal={openRescheduleModal}
          toggleModal={toggleRescheduleModal}
          meeting={selectedMeeting}
        />
      )}
      {selectedMeetingId && (
        <AskForRescheduleModal
          openResModal={showAskForRescheduleModal}
          closeResModalHandler={() => setShowAskForRescheduleModal(false)}
          meetingId={selectedMeetingId}
        />
      )}
    </>
  );
};

export default Meetings;
