import Swal from "sweetalert2";
import theme from "../theme/theme";
export const errorResponseHandler = (title, msg) => {
  return Swal.fire({
    icon: "error",
    title: title,
    text: msg,
    confirmButtonText: "Close",
    confirmButtonColor: theme.palette.primary.main,
  });
};

export const successResponseHandler = (title, msg) => {
  return Swal.fire({
    icon: "success",
    title: title,
    text: msg,
    confirmButtonText: "Close",
  });
};
