import { Box, Container, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getAllScheduleApi } from "../../../API/schedule";
import { setAllSchedulesSuccess as setAllSchedulesAction } from "../../../store/actions/schedule";
import { errorResponseHandler } from "../../../utils/reposeAlert";
import Layout from "../../Layout/Layout";
import DashboardHeader from "./DashboardHeader";
import MeetingTable from "./MeetingTable";
const Home = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    getAllSchedules();
  }, []);

  const getAllSchedules = async () => {
    await getAllScheduleApi()
      .then((response) => {
        if (response.status === 200) {
          dispatch(setAllSchedulesAction(response.data.data.rows));
        }
      })
      .catch((error) => {
        const msg = error.response.data;
        errorResponseHandler("Login Failed", msg);
      })
      .finally({});
  };
  return (
    <Layout>
      <Container maxWidth="xl">
        <Box mt={4}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={12}>
              <DashboardHeader />
            </Grid>
            <Grid item xs={12} md={12}>
              <MeetingTable />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Layout>
  );
};

export default Home;
